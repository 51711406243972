import React, { useState, useEffect } from "react";
import {
    useFlatfile,
    usePlugin,
    useEvent,
    useListener,
    Workbook,
    Space,
    FlatfileProvider,
} from "@flatfile/react";
import { recordHook } from "@flatfile/plugin-record-hook";
import { referenceDataWorkbook } from "../configs/workbook";
import "./WorkbooksPage.css";
import { useNavigate } from "react-router-dom";
import config from "./config";
import api  from "@flatfile/api";
import { useAuth0 } from '@auth0/auth0-react'; 
import { getUserRole } from '../utils/getUserRole';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../MSGraphAuthConfig';
import { callMsGraph, triggerRefreshForReport, getPowerBiGroups, uploadFileToSharePoint } from '../MSGraph';
import axios from 'axios';

const WorkbooksPageRef = () => {
    const { user } = useAuth0(); 
    const [selectedSidebar, setSelectedSidebar] = useState("Reference data");
    const PUBLISHABLE_KEY = "pk_17dd17c7c44a4dbeb844920b883a3c05";
    const navigate = useNavigate();
    const [companyData, setCompanyData]             = useState(JSON.parse(localStorage.getItem("companyData")));
    const company_id = companyData.id;

    const [validCurrencies, setValidCurrencies]     = useState([]);
    const [workbook_ref, setWorkbookRef]            = useState([]);
    const [workbook_sale, setWorkbookSale]          = useState([]);
    const [workbook_service, setWorkbookService]    = useState([]);
    const [csvData, setCsvData] = useState(null);
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [siteId, setSiteId] = useState(null);
    
    useEffect(() => {
        const authenticateUser = async () => {
            try {
                // Fetch Azure Access Token
                const { data: tokenData } = await axios.get(`${config.API_URL}/getAzureToken.php`);
                if (!tokenData.access_token) throw new Error("Azure access token not received.");

                // Fetch Microsoft Graph Data
                const graphResponse = await callMsGraph(tokenData.access_token);
                setGraphData(graphResponse);
                setSiteId(graphResponse.id);

                // Fetch Power BI Access Token
                const { data: powerBIAccessTokenData } = await axios.post(`${config.API_URL}/accessToken_powerbi.php`);
                if (!powerBIAccessTokenData.access_token) throw new Error("Power BI access token not received.");

                // Fetch Power BI Groups
                await getPowerBiGroups(powerBIAccessTokenData.access_token);

            } catch (error) {
                console.error("Authentication error:", error);

                if (error.errorCode === "interaction_required") {
                    console.warn("Silent authentication failed. Triggering login popup...");
                    try {
                        await instance.loginPopup(loginRequest);
                        window.location.reload();
                    } catch (popupError) {
                        console.error("Popup login failed:", popupError);
                    }
                }
            } 
        };

        authenticateUser();
    }, [instance, accounts]);


    const fetchFlatfileDataForWorkbook = async (workbook_name) => {
        try {
            const response = await fetch(`${config.API_URL}/fetch-flatfile-data.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ companyData, workbook_name }),
            });
    
            const data = await response.json();
    
            if (data.status === "success" && data.data) {
                const sheets_records = data.data;
    
                const mappings = {
                    reference: ["Products", "Channels", "ExchangeRates", "Clients", "ClientBranches"],
                    sale: ["InvoiceLines"],
                    service: ["PurchaseOrderRecieved", "PurchaseOrderRecievedLines"],
                };
    
                if (!mappings[workbook_name]) {
                    throw new Error(`Invalid workbook name provided: ${workbook_name}`);
                }
    
                const mappedRecords = mappings[workbook_name].reduce((acc, key) => {
                    acc[key] = [];
                    return acc;
                }, {});
    
                let count = 0;
                for (const [key, records] of Object.entries(sheets_records)) {
                    if (records && records.length > 0) {
                        const targetKey = mappings[workbook_name][count];
                        if (targetKey) {
                            mappedRecords[targetKey] = records;
                        } else {
                            console.warn(`No target mapping for index ${count} in workbook '${workbook_name}'`);
                        }
                    } else {
                        console.warn(`No records found for key '${key}'`);
                    }
                    count++;
                }
    
                switch (workbook_name) {
                    case "reference":
                        setWorkbookRef(mappedRecords);
                        break;
                    case "sale":
                        setWorkbookSale(mappedRecords);
                        break;
                    case "service":
                        setWorkbookService(mappedRecords);
                        break;
                    default:
                        console.warn(`Unhandled workbook type: ${workbook_name}`);
                }
    
            } else {
                const errorMessage = data.message || "Invalid data structure received";
                console.error("Failed to fetch dynamic columns or records:", errorMessage);
            }
        } catch (error) {
            console.error("Error during workbook or record processing:", error.message || error);
        }
    };
    function convertToCSV(data) {
        if (!data || data.length === 0) return "";
    
        const keys = Object.keys(data[0]);
        const csvRows = data.map(row => keys.map(key => JSON.stringify(row[key] || "")).join(","));
        
        return [keys.join(","), ...csvRows].join("\n");
    }
    const handleSidebarClick = (sidebar) => {
        setSelectedSidebar(sidebar);
        const routes = {
            "Reference data": "/workbooks_ref",
            "Sales data": "/workbooks_sale",
            "Service data": "/workbooks_service",
        };
        navigate(routes[sidebar] || "/workbooks_ref");
    };

    const sendDataToApi = async (companyData, refferenceWorkbookId, saleWorkbookId, serviceWorkbookId) => {
        try {
            const response = await fetch(`${config.API_URL}/save-data.php`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ companyData, refferenceWorkbookId, saleWorkbookId, serviceWorkbookId }),
            });
    
            const responseText = await response.text(); // Get raw response as text
    
            if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
    
            // Check if the response text is empty
            if (!responseText) {
                throw new Error("The server returned an empty response.");
            }
    
            let result;
            try {
                result = JSON.parse(responseText); // Try parsing the JSON response
            } catch (error) {
                console.error("Failed to parse response as JSON:", error);
                throw new Error("The server returned an invalid JSON response.");
            }
    
            if (result.status !== "success") {
                console.error("Error saving data:", result.message);
            }
        } catch (error) {
            console.error("API request failed:", error);
        }
    };

    const SpaceConfig = () => {
        const { openPortal }            = useFlatfile();
        const [channels, setChannels]   = useState(workbook_ref.Channels);
        const [clients, setClients]     = useState(workbook_ref.Clients);



        useEffect(() => {
            openPortal();
        }, [openPortal]);
        
        useEffect(() => {
            // Fetch the list of valid currencies on component mount
            const fetchCurrencies = async () => {
                try {
                    const response = await fetch(`${config.API_URL}/fetch_currencies.php`);
                    const data = await response.json();
                    const currencies = data.map((currency) => currency.currency_code);

                    // Avoid unnecessary state updates
                    setValidCurrencies((prevCurrencies) => {
                        if (JSON.stringify(prevCurrencies) !== JSON.stringify(currencies)) {
                            return currencies;
                        }
                        return prevCurrencies;
                    });
                } catch (error) {
                    console.error("Error fetching currencies:", error);
                }
            };

            fetchCurrencies();
        }, []);

        
        
        useEvent("job:ready", { job: "sheet:submitActionFg" }, async (event) => {
            const { jobId, workbookId } = event.context;
        
            try {
                const fetchUserRole = await getUserRole(user.email);
                const userRole = fetchUserRole.role;
        
                if (!["admin", "collaborator"].includes(userRole)) {
                    console.error("User does not have permission to submit data.");
                    await api.jobs.fail(jobId, {
                        outcome: { message: "You do not have permission to submit data." },
                    });
                    return;
                }
        
                await api.jobs.ack(jobId, { info: "Submitting the data.", progress: 30 });
        
                const referenceWorkbookId = workbookId;
                const saleWorkbookId = null;
                const serviceWorkbookId = null;
        
                await sendDataToApi(companyData, referenceWorkbookId, saleWorkbookId, serviceWorkbookId);
        
                let accessToken = null;
                try {
                    if (accounts.length > 0) {
                        const response = await instance.acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        });
                        accessToken = response.accessToken;
                    } else {
                        const { data } = await axios.get(`${config.API_URL}/getAzureToken.php`);
                        accessToken = data.access_token;
                    }
        
                    if (!accessToken) {
                        throw new Error("Failed to acquire access token.");
                    }
        
                    try {
                        const workbook_name = "reference";
                        const response = await fetch(`${config.API_URL}/fetch-flatfile-data.php`, {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ companyData, workbook_name }),
                        });
        
                        const responseData = await response.json();
                        if (!responseData.data) {
                            throw new Error("Invalid data received from fetch-flatfile-data.php");
                        }
                        let count = 1; 
                        for (const [sheetName, records] of Object.entries(responseData.data)) {
                            if (!Array.isArray(records) || records.length === 0) {
                                continue;
                            }
                
                            // Extract all possible keys dynamically
                            const allKeys = new Set();
                            records.forEach(record => {
                                Object.keys(record).forEach(key => allKeys.add(key));
                            });
                
                            const headers = Array.from(allKeys); // Convert Set to Array
                            let csvContent = headers.join(",") + "\n"; // CSV header row
                
                            // Process each record dynamically
                            records.forEach(record => {
                                let row = headers.map(header => {
                                    return record[header]?.value !== undefined ? `"${record[header].value}"` : '""';
                                }).join(",");
                                csvContent += row + "\n";
                            });
                
                            // Create a Blob for CSV
                            const csvBlob = new Blob([csvContent], { type: "text/csv" });
                            let fileName = ""; // Declare outside the loop

                            if (count === 1) {
                                fileName = "Products.csv";
                            } else if (count === 2) {
                                fileName = "Channels.csv";
                            } else if (count === 3) {
                                fileName = "ExchangeRates.csv";
                            } else if (count === 4) {
                                fileName = "Clients.csv";
                            } else if (count === 5) {
                                fileName = "ClientBranches.csv";
                            }

                
                            // Upload CSV to SharePoint
                            await uploadFileToSharePoint(accessToken, siteId, company_id, csvBlob, fileName);
                            count++;
                        }
                
                        console.log("All files uploaded successfully!");
                    } catch (error) {
                        console.error("Error uploading file to SharePoint:", error.message);
                    }
        
                    await triggerRefreshForReport(company_id);
                } catch (error) {
                    console.error("Upload process error:", error.message);
                }
        
                await api.jobs.complete(jobId, {
                    outcome: { message: "The data has been submitted successfully." },
                });
        
            } catch (error) {
                console.error("Job processing error:", error.message);
                await api.jobs.fail(jobId, {
                    outcome: { message: "This job encountered an error." },
                });
            }
        });

        useListener((listener) => {

            listener.on("workbook:created", async (event) => {
                fetchFlatfileDataForWorkbook('reference');
                 
                const { workbookId } = event.context;
                const workbook_name     = 'reference'; 
                try {
                    const response = await fetch(`${config.API_URL}/fetch-flatfile-data.php`, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ companyData, workbook_name }),
                    });
        
                    const data = await response.json();
        
                    if ( data.status === "success" && data.data) {
                        const sheets_records = data.data;
                        
                        let count = 0; 
                        for (const key in sheets_records) {
                            if (sheets_records.hasOwnProperty(key)) {
                                const records = sheets_records[key];                         
                                if (records && records.length > 0) {
                                
                                    try {
                                    const workbook = await api.workbooks.get(workbookId);
                                
                                    if (workbook && workbook.data && workbook.data.sheets) {
                                        const sheetId = workbook.data.sheets[count].id; 
                                
                                        const response = await api.records.insert(sheetId, records);
                                    } else {
                                        console.error("No sheets found in the workbook."); 
                                    }
                                    } catch (error) {
                                    console.error("Error during workbook or record insertion:", error);
                                    }
                                } else {
                                    console.error("No records found in the response.");
                                }
                            }
                            count++; 
                        }
                    } else {
                        console.error("Failed to fetch dynamic columns or records:", data.message || "Invalid data structure");
                    }
                } catch (error) {
                    console.error("Error during workbook or record insertion:", error);
                }
            });
        });

        usePlugin(
            recordHook("products", (record) => {
                const productsData = {
                    ID: record.get("ID"),
                    Name: record.get("Name"),
                    Description: record.get("Description"),
                    ProductGroupID: record.get("GroupID"),
                    ProductType: record.get("Type"),
                    
                };
                if (!productsData.ID) {
                    record.addError("ID", "Product ID is required.");
                }
                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                }
                productsData.rowId = recordId;
        
           
                return record; 
            })
        );  
        
        usePlugin(
            recordHook("clients", async (record) => {
                // Log the record to inspect available fields
        
                // Extract fields using `record.get()`
                const clientData = {
                    ID: {value: record.get("ID")},
                    Name: {value: record.get("Name")},
                    Description: {value: record.get("Description")},
                    Currency: {value: record.get("Currency")},
                };
        
                if (!clientData.ID) {
                    record.addError("ID", "Client ID is required."); // Add an error if ID is missing
                }
        
                // Use `recordId` as the unique identifier
                const recordId = record.rowId || record.get("rowId"); // Adjust if necessary
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                }
        
                // Add the `recordId` to the client data
                clientData.rowId = recordId;
        
                
                // Validate Currency field
                if (!validCurrencies.includes(clientData.Currency.value)) {
                    record.addError("Currency", "Invalid currency. Please enter a valid currency from the list of supported currencies.");
                }


                // Store the Client data in the `Clients` state
                setClients((prev) => {
        
                    // Update the state by replacing or adding the new record
                    const updatedClients = [
                        ...prev.filter((client) => client.rowId !== recordId), // Remove the old record if it exists
                        clientData, // Add the new or updated record
                    ];
        
                    return updatedClients;
                });

                return record; // Return the processed record
            })
        );        
        
        usePlugin(
            recordHook("channels", (record) => {
        
                // Extract fields using `record.get()`
                const channelData = {
                    ID: {
                        value:  record.get("ID"),
                    },
                    Name: {
                        value: record.get("Name"),
                    },
                    Description: {
                        value: record.get("Description"),
                    }
                };

                if (!channelData.ID) {
                    record.addError("ID", "Channel ID is required.");
                }
        
                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                }
                channelData.rowId = recordId;
                // Store the channel data in the `channels` state
                setChannels((prev) => {
        
                    // Update the state by replacing or adding the new record
                    const updatedChannels = [
                        ...prev.filter((channel) => channel.rowId !== recordId), // Remove the old record if it exists
                        channelData, // Add the new or updated record
                    ];
        
                    return updatedChannels;
                });

                return record;
            })
        );

        usePlugin(
            recordHook("client_branches", (record) => {
        
                // Extract fields using `record.get()`
                const clientBranchData = {
                    ID: record.get("ID"), // Unique identifier for the branch
                    Name: record.get("Name"),
                    Description: record.get("Description"),
                    ClientID: record.get("ClientID"), // Foreign key reference to clients
                    ChannelID: record.get("ChannelID"), // Foreign key reference to channels
                    Location: record.get("Location"),
                };
        
        
                // Check if required fields are missing
                if (!clientBranchData.ID) {
                    record.addError("ID", "Branch ID is required."); // Add an error if ID is missing
                }
                if (!clientBranchData.ClientID) {
                    record.addError("ClientID", "Client ID is required."); // Add an error if Client ID is missing
                }
                if (!clientBranchData.ChannelID) {
                    record.addError("ChannelID", "Channel ID is required."); // Add an error if Channel ID is missing
                }
                if (!clientBranchData.Location) {
                    record.addError("Location", "Location is required."); // Add an error if Location is missing
                }
        
                // Use `record.rowId` as the unique identifier
                const recordId = record.rowId || record.get("rowId"); // Adjust if necessary
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                }
        
                // Add the `recordId` to the client branch data
                clientBranchData.rowId = recordId;
        
        
                // Validate the foreign key (ClientID) against `clients`
                const clientId = clientBranchData.ClientID;
                if (!clients.some((client) => client.ID.value === clientId)) {
                    const errorMsg = `Client ID ${clientId} does not exist in the Clients table.`;
                    record.addError("ClientID", errorMsg); // Add an error for the invalid ClientID
                }
        
                // Validate the foreign key (ChannelID) against `channels`
                const channelId = clientBranchData.ChannelID;
                if (!channels.some((channel) => channel.ID.value === channelId)) {
                    const errorMsg = `Channel ID ${channelId} does not exist in the Channels table.`;
                    record.addError("ChannelID", errorMsg);
                }

                return record;
            })
        );

        usePlugin(
            recordHook("exchange_rates", async (record) => {
                
                const exchangeRatesData = {
                    OtherCurrency: record.get("otherCurrency"),
                    RateToBaseCurrency: record.get("RateToBaseCurrency"),
                    EffectiveFrom: record.get("EffectiveFrom"),
                };

                const recordId = record.rowId || record.get("rowId");
                if (!recordId) {
                    console.error("Record Missing rowId:", record);
                }
        
                exchangeRatesData.rowId = recordId;
        
            
                // Validate OtherCurrency field
                if (!validCurrencies.includes(exchangeRatesData.OtherCurrency)) {
                    record.addError("otherCurrency", "Invalid currency. Please enter a valid currency from the list of supported currencies.");
                }
              
                // Validate RateToBaseCurrency field (should be a valid number: float or int)
                const rateToBaseCurrency = parseFloat(exchangeRatesData.RateToBaseCurrency);
                if (isNaN(rateToBaseCurrency) || !/^[-+]?\d+(\.\d+)?$/.test(exchangeRatesData.RateToBaseCurrency)) {
                    record.addError("RateToBaseCurrency", "Rate must be a valid number.");
                }else if( rateToBaseCurrency < 0 ){
                    record.addError("RateToBaseCurrency", "Rate must be a non-negative number.");    
                }

                const validDateFormat = companyData.date_format === 'mm/dd/yyyy' 
                ? /^\d{1,2}\/\d{1,2}\/\d{4}$/  // Matches mm/dd/yyyy format
                : /^\d{1,2}\/\d{1,2}\/\d{4}$/; // Matches dd/mm/yyyy format (same regex, but validation differs)
            
                const effectiveFromDate = exchangeRatesData.EffectiveFrom;
                
                if (!validDateFormat.test(effectiveFromDate)) {
                    record.addError("EffectiveFrom", "Effective From must be a valid date in the format " + companyData.date_format + ".");
                } else {
                    const [part1, part2, year] = effectiveFromDate.split('/').map(num => parseInt(num, 10));
                    let month, day;
                
                    // Adjust based on the date format
                    if (companyData.date_format === 'mm/dd/yyyy') {
                        month = part1;
                        day = part2;
                    } else if (companyData.date_format === 'dd/mm/yyyy') {
                        day = part1;
                        month = part2;
                    }
                
                    // Check if the date is valid
                    const date = new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
                
                    if (date.getMonth() !== month - 1 || date.getDate() !== day || date.getFullYear() !== year) {
                        record.addError("EffectiveFrom", "Effective From is not a valid calendar date.");
                    }
                }   
                
                return record;
            })
        );        

        return (
            <div className="content">
               
                <Space
                    config={{
                        name: `${company_id}_refference`, 
                        metadata: {
                            sidebarConfig: { showSidebar: false },
                            theme: { root: { actionColor: "#1C83CB" } },
                            showSpaceInfo: false,
                        },
                    }}
                >
                    <div className="workbooks-layout">
                        <Workbook
                            config={referenceDataWorkbook}
                            onData={(data) => {
                                const csvString = convertToCSV(data.records); // Convert data to CSV
                                setCsvData(csvString);
                            }}
                            onRecordHooks={[
                                [(record) => record],
                                [(record) => record],
                            ]}
                        />
                    </div>
                </Space>
            </div>
        );
    };

    return (
        <div className="workbooks-container">
            <div className="sidebar">
                <div className="icon-section">
                    <div 
                        className={`icon ${selectedSidebar === 'Reference data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Reference data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 9C11.2044 9 10.4413 8.68393 9.87868 8.12132C9.31607 7.55871 9 6.79565 9 6C9 5.20435 9.31607 4.44129 9.87868 3.87868C10.4413 3.31607 11.2044 3 12 3C12.7956 3 13.5587 3.31607 14.1213 3.87868C14.6839 4.44129 15 5.20435 15 6C15 6.79565 14.6839 7.55871 14.1213 8.12132C13.5587 8.68393 12.7956 9 12 9ZM5.5 21C4.70435 21 3.94129 20.6839 3.37868 20.1213C2.81607 19.5587 2.5 18.7956 2.5 18C2.5 17.2044 2.81607 16.4413 3.37868 15.8787C3.94129 15.3161 4.70435 15 5.5 15C6.29565 15 7.05871 15.3161 7.62132 15.8787C8.18393 16.4413 8.5 17.2044 8.5 18C8.5 18.7956 8.18393 19.5587 7.62132 20.1213C7.05871 20.6839 6.29565 21 5.5 21ZM18.5 21C17.7044 21 16.9413 20.6839 16.3787 20.1213C15.8161 19.5587 15.5 18.7956 15.5 18C15.5 17.2044 15.8161 16.4413 16.3787 15.8787C16.9413 15.3161 17.7044 15 18.5 15C19.2956 15 20.0587 15.3161 20.6213 15.8787C21.1839 16.4413 21.5 17.2044 21.5 18C21.5 18.7956 21.1839 19.5587 20.6213 20.1213C20.0587 20.6839 19.2956 21 18.5 21Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path opacity="0.5" d="M20 13C20.0014 11.8648 19.7605 10.7424 19.2935 9.70776C18.8265 8.67309 18.1442 7.74995 17.292 7M4.00001 13C3.99866 11.8648 4.23952 10.7424 4.7065 9.70776C5.17348 8.67309 5.85584 7.74995 6.70801 7M10 20.748C10.6534 20.916 11.3254 21.0007 12 21C12.6746 21.0007 13.3466 20.916 14 20.748" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>

                    </div>

                    <div 
                        className={`icon center ${selectedSidebar === 'Sales data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Sales data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.5" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0077BE" strokeWidth="1.5"/>
                            <path d="M12 17V18M12 17C13.657 17 15 15.88 15 14.5C15 13.12 13.657 12 12 12C10.343 12 9 10.88 9 9.5C9 8.12 10.343 7 12 7M12 17C10.343 17 9 15.88 9 14.5M12 6V7M12 7C13.657 7 15 8.12 15 9.5" stroke="#0077BE" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>
                
                    </div>

                    <div 
                        className={`icon invalid-count ${selectedSidebar === 'Service data' ? 'active-sidebar' : ''}`} 
                        onClick={() => handleSidebarClick('Service data')}
                    >
                        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.2702 16.265L20.9752 12.185C21.0166 11.9459 21.0052 11.7006 20.9419 11.4663C20.8786 11.232 20.7648 11.0144 20.6086 10.8287C20.4524 10.643 20.2575 10.4936 20.0376 10.391C19.8176 10.2885 19.5779 10.2352 19.3352 10.235H14.1532C14.0324 10.2349 13.913 10.2085 13.8034 10.1576C13.6938 10.1068 13.5966 10.0327 13.5185 9.94049C13.4404 9.8483 13.3833 9.74023 13.3511 9.62376C13.3189 9.50729 13.3125 9.38522 13.3322 9.26601L13.9952 5.22101C14.1023 4.56422 14.0717 3.89233 13.9052 3.24801C13.8333 2.9819 13.6954 2.73823 13.5044 2.5395C13.3133 2.34077 13.0753 2.1934 12.8122 2.11101L12.6672 2.06401C12.3396 1.95938 11.9845 1.9837 11.6742 2.13201C11.3342 2.29601 11.0862 2.59501 10.9942 2.95001L10.5182 4.78401C10.3669 5.36772 10.1467 5.93135 9.86223 6.46301C9.44623 7.24001 8.80423 7.86301 8.13723 8.43801L6.69723 9.67801C6.49759 9.85052 6.34168 10.0679 6.24225 10.3123C6.14283 10.5567 6.10272 10.8211 6.12523 11.084L6.93823 20.477C6.974 20.8922 7.16411 21.279 7.47105 21.5609C7.77798 21.8429 8.17945 21.9995 8.59623 22H13.2452C16.7262 22 19.6972 19.574 20.2692 16.265" fill="#0077BE"/>
                            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M2.96808 9.485C3.16134 9.47655 3.35039 9.5431 3.49574 9.67075C3.64109 9.7984 3.7315 9.97727 3.74808 10.17L4.71808 21.406C4.73451 21.5733 4.71666 21.7422 4.66562 21.9024C4.61458 22.0626 4.53142 22.2107 4.42122 22.3377C4.31102 22.4647 4.17609 22.5678 4.02468 22.6409C3.87327 22.714 3.70855 22.7555 3.54058 22.7627C3.37261 22.77 3.20492 22.743 3.04775 22.6833C2.89058 22.6236 2.74723 22.5325 2.62646 22.4155C2.50568 22.2986 2.41002 22.1582 2.3453 22.003C2.28059 21.8479 2.24819 21.6811 2.25008 21.513V10.234C2.25016 10.0407 2.32488 9.85486 2.45866 9.71531C2.59244 9.57576 2.77494 9.49325 2.96808 9.485Z" fill="#0077BE"/>
                        </svg>

                    </div>
                </div>
            </div>

            <FlatfileProvider publishableKey={PUBLISHABLE_KEY}>
                <SpaceConfig />
            </FlatfileProvider>
        </div>
    );
};

export default WorkbooksPageRef;
