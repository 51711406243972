import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../MSGraphAuthConfig';
import { callMsGraph, triggerRefreshForReport, getPowerBiGroups } from '../MSGraph';
import UploadFile from './UploadFile';
import config from './config';
import axios from 'axios';

const PageDataContent = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadStatus, setUploadStatus] = useState("");

    // Load company ID from local storage with a fallback
    const [companyData] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem("companyData")) || {};
        } catch (error) {
            console.error("Error parsing companyData from local storage:", error);
            return {};
        }
    });

    const company_ID = companyData?.id;

    useEffect(() => {
        const authenticateUser = async () => {
            try {
                // Fetch Azure Access Token
                const { data: tokenData } = await axios.get(`${config.API_URL}/getAzureToken.php`);
                if (!tokenData.access_token) throw new Error("Azure access token not received.");

                // Fetch Microsoft Graph Data
                const graphResponse = await callMsGraph(tokenData.access_token);
                setGraphData(graphResponse);
                setSiteId(graphResponse.id);

                // Fetch Power BI Access Token
                const { data: powerBIAccessTokenData } = await axios.post(`${config.API_URL}/accessToken_powerbi.php`);
                if (!powerBIAccessTokenData.access_token) throw new Error("Power BI access token not received.");

                // Fetch Power BI Groups
                await getPowerBiGroups(powerBIAccessTokenData.access_token);

            } catch (error) {
                console.error("Authentication error:", error);

                if (error.errorCode === "interaction_required") {
                    console.warn("Silent authentication failed. Triggering login popup...");
                    try {
                        await instance.loginPopup(loginRequest);
                        window.location.reload();
                    } catch (popupError) {
                        console.error("Popup login failed:", popupError);
                        setErrorMessage("Authentication failed. Please try again.");
                    }
                } else {
                    setErrorMessage(error.message || "Authentication failed. Please try again.");
                }
            } finally {
                setIsLoading(false);
            }
        };

        authenticateUser();
    }, [instance, accounts]);

    const handleUploadSuccess = async (fileName) => {
        try {
            setUploadStatus("Processing uploaded file...");

            // Acquire Access Token
            let accessToken = null;
            if (accounts.length > 0) {
                // User is logged in
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                });
                accessToken = response.accessToken;
            } else {
                // Use App Credentials Flow if no user logged in
                const { data } = await axios.get(`${config.API_URL}/getAzureToken.php`);
                accessToken = data.access_token;
            }

            if (!accessToken) throw new Error("Failed to acquire access token.");

            // Trigger Power BI Refresh
            const powerBIResponse = await triggerRefreshForReport(company_ID);
            setUploadStatus(`Power BI Update Triggered: ${powerBIResponse}`);

        } catch (error) {
            console.error("Upload process error:", error);
            setUploadStatus(error.message || "An error occurred.");
        }
    };

    if (isLoading) return <p>Loading...</p>;
    if (errorMessage) return <p style={{ color: "red" }}>{errorMessage}</p>;

    return (
        <>
            {graphData ? (
                <>
                    {siteId && <UploadFile companyId={company_ID} siteId={siteId} onUploadSuccess={handleUploadSuccess} />}
                    <p>{uploadStatus}</p>
                </>
            ) : (
                <p>Loading SharePoint data...</p>
            )}
        </>
    );
};

const MainContent = () => (
    <div className="App">
        <PageDataContent />
    </div>
);

export default function App() {
    return <MainContent />;
}
