import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { validateUser } from './utils/validateUser';
import config from './components/config';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './MSGraphAuthConfig';


const msalInstance = new PublicClientApplication(msalConfig);

const Root = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  useEffect(() => {
   
    if (isAuthenticated && user) {
      validateUser(user.email, logout);
    }
  }, [isAuthenticated, user, logout]);

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <MsalProvider instance={msalInstance}>
        <Root />
      </MsalProvider>

    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();