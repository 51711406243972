import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Topbar.css';
import { useAuth0 } from '@auth0/auth0-react'; 
import { getUserRole } from '../utils/getUserRole';

const Topbar  = () => {
    const { user, logout } = useAuth0(); 
    const currentHour = new Date().getHours();
    const greeting = currentHour < 12 ? 'Good Morning' : 'Good Afternoon';
    const [userRole, setUserRole] = useState('');

    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);

    const userMenuRef = useRef(null);
    const notificationMenuRef = useRef(null);

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            logout({
                returnTo: window.location.origin,
                logoutParams: {
                    federated: true,
                },
            });
            
            localStorage.removeItem("companyData");
            localStorage.removeItem("userData");
            
            localStorage.removeItem("products");
            localStorage.removeItem("clients");
            localStorage.removeItem("exchangeRates");
            localStorage.removeItem("channels");
            localStorage.removeItem("clientBranches");
            localStorage.removeItem("invoiceLines");
            localStorage.removeItem("purchaseOrders");
            localStorage.removeItem("purchaseOrdersLines");

        } catch (error) {
            console.error("Logout failed", error);
        }
    };

    const handleSettingsClick = () => {
        navigate('/settings');
    };

    const handleLogoClick = () => {
        navigate('/dashboard');
    };

    const toggleUserMenu = () => setShowUserMenu(!showUserMenu);
    const toggleNotifications = () => setShowNotifications(!showNotifications);

    useEffect( () => {
        const initializeFunction = async () => {
            try {
                const fetchUserRole = await getUserRole(user.email);
                if (fetchUserRole && fetchUserRole.success && fetchUserRole.role) {
                    setUserRole(fetchUserRole.role);
                } else {
                    setUserRole(null);
                }
            } catch (error) {
                setUserRole(null);
            }
        };
    
        initializeFunction();
    

        const handleClickOutside = (event) => {
            const isUserMenuClick = userMenuRef.current?.contains(event.target);
            const isNotificationMenuClick = notificationMenuRef.current?.contains(event.target);
            const isDropdownClick = event.target.closest('.dropdown');

            if (!isUserMenuClick && !isNotificationMenuClick && !isDropdownClick) {
                setShowUserMenu(false);
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const firstName = user?.name ? user.name.split(' ')[0] : 'User'; // Get the first name or default to 'User'

    return (
        <div className="container-fluid topbar-container">
            <div className="topbar">
                <div className="left-section">
                    <img onClick={handleLogoClick} src="/logo.png" alt="App Logo" className="logo" />
                    <h3>{greeting}, {firstName}</h3>
                </div>
                <div className="right-section">
                    <div className="icon bell" onClick={toggleNotifications} ref={notificationMenuRef}>
                        <img src="/bell-icon.svg" alt="Notifications" />
                    </div>
                    {showNotifications && (
                        <div className="dropdown notifications-dropdown">
                            <p>No new notifications.</p>
                        </div>
                    )}

                    {userRole === 'admin' && (
                        <div className="icon" onClick={handleSettingsClick}>
                            <img src="/settings-icon.svg" alt="Settings" />
                        </div>
                    )}

                    <div className="user-initials icon" onClick={toggleUserMenu} ref={userMenuRef}>
                        {firstName.charAt(0)} 
                    </div>
                    {showUserMenu && (
                        <div className="dropdown user-dropdown">
                             <p className='logout-btn-container' onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> Logout</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Topbar;
