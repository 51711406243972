import { graphConfig } from "./MSGraphAuthConfig";
import axios from "axios";
import config from './components/config';


// Microsoft API URLs
const POWER_BI_BASE_URL = "https://api.powerbi.com/v1.0/myorg";
const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

/**
 * Fetches the access token for Power BI API
 */
export const getAccessToken = async () => {
    try {
        const response = await axios.post(`${config.API_URL}/accessToken_powerbi.php`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        });

        const data = response.data;
        if (!data.access_token) throw new Error("Access token not received.");

        return data.access_token;
    } catch (error) {
        console.error("Error fetching access token:", error.message);
        return null;
    }
};

/**
 * Calls Microsoft Graph API
 */
export const callMsGraph = async (accessToken) => {
    try {
        const response = await axios.get(graphConfig.graphGetDefaultSiteEndpoint, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        return response.data;
    } catch (error) {
        console.error("Error calling Microsoft Graph API:", error.response?.data || error.message);
        return null;
    }
};

/**
 * Uploads a file to SharePoint
 */
export const uploadFileToSharePoint = async (accessToken, siteId, companyId, file, fileName) => {
    try {
        const response = await axios.put(
            graphConfig.graphUploadFileEndpoint(siteId, companyId, fileName),
            file,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "Content-Type": file.type,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error("Error uploading file to SharePoint:", error.response?.data || error.message);
        return null;
    }
};

/**
 * Fetches Power BI workspaces (groups) only if accessToken is provided.
 */
export const getPowerBiGroups = async (accessToken) => {
  if (accessToken) {
    try {
        const response = await axios.get(`${POWER_BI_BASE_URL}/groups`, {
            headers: { Authorization: `Bearer ${accessToken}`, Accept: "application/json" },
        });
  
        return response.data.value;
    } catch (error) {
        console.error("Error fetching Power BI groups:", error.response?.data || error.message);
        return null;
    }
  }else{
    console.error("ACCESS TOKEN NOT AVAILABLE");
    return null;
  }

};


/**
 * Fetches Power BI datasets in a group
 */
export const getPowerBIDatasets = async (accessToken, groupId) => {
    try {
        const url = `${POWER_BI_BASE_URL}/groups/${groupId}/datasets`;
        const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });
        return response.data.value;
    } catch (error) {
        console.error("Error fetching Power BI datasets:", error.response?.data || error.message);
        return null;
    }
};

/**
 * Waits for a Power BI dataset refresh to complete
 */
export const waitForDatasetRefresh = async (accessToken, groupId, datasetId, maxRetries = 10, delay = 10000) => {
    try {
        const url = `${POWER_BI_BASE_URL}/groups/${groupId}/datasets/${datasetId}/refreshes`;

        for (let i = 0; i < maxRetries; i++) {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            const refreshes = response.data.value;
            if (refreshes.length > 0) {
                const latestRefresh = refreshes[0];

                if (latestRefresh.status === "Completed") {
                    console.log("Dataset refresh completed.");
                    return true;
                } else if (latestRefresh.status === "Failed") {
                    console.error("Dataset refresh failed:", latestRefresh);
                    return false;
                }
            }

            console.log(`Waiting for dataset refresh... Attempt ${i + 1}/${maxRetries}`);
            await new Promise(resolve => setTimeout(resolve, delay));
        }

        console.error("Dataset refresh did not complete within the expected time.");
        return false;
    } catch (error) {
        console.error("Error checking dataset refresh status:", error.response?.data || error.message);
        return false;
    }
};

/**
 * Find Group ID by Workspace Name
 */
export const getGroupIdByWorkspaceName = async (accessToken, workspaceName) => {
    try {
        const groups = await getPowerBiGroups(accessToken);
        if (!groups) throw new Error("No groups found");

        const group = groups.find(group => group.name === workspaceName);
        if (!group) throw new Error(`Workspace with name '${workspaceName}' not found`);

        return group.id;
    } catch (error) {
        console.error("Error finding group by workspace name:", error.message);
        return null;
    }
};

/**
 * Refreshes a Power BI dataset
 */
export const refreshPowerBIDataset = async (accessToken, groupId, datasetId) => {
    try {
        console.log("Triggering refresh for dataset:", datasetId, "in group:", groupId);

        const url = `${POWER_BI_BASE_URL}/groups/${groupId}/datasets/${datasetId}/refreshes`;

        await axios.post(url, {}, {
            headers: { Authorization: `Bearer ${accessToken}` },
        });

        console.log("Refresh triggered successfully.");
        return "Power BI dataset refresh triggered successfully!";
    } catch (error) {
        console.error("Failed to refresh Power BI dataset:", error.response?.data || error.message);
        return null;
    }
};

/**
 * Triggers dataset refresh and fetches updated report URL
 */
export const triggerRefreshForReport = async (workspaceName, reportId) => {
    try {
        const accessToken = await getAccessToken();
        if (!accessToken) throw new Error("Failed to obtain access token");

        const groupId = await getGroupIdByWorkspaceName(accessToken, workspaceName);
        if (!groupId) throw new Error("Failed to find group ID");
        console.log("GroupID: ", groupId);
        const datasets = await getPowerBIDatasets(accessToken, groupId);
        if (!datasets || datasets.length === 0) throw new Error("No datasets found for the group");

        const datasetId = datasets[0].id;
        console.log("datasetId: ", datasetId);
        await refreshPowerBIDataset(accessToken, groupId, datasetId);

        const refreshCompleted = await waitForDatasetRefresh(accessToken, groupId, datasetId);
        if (!refreshCompleted) throw new Error("Dataset refresh failed or took too long");

        return true;
    } catch (error) {
        console.error("Error triggering Power BI report refresh:", error.message);
        return null;
    }
};
