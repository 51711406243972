import React, { useEffect, useState } from 'react';
import './ReportsPage.css';
import config from './config';

const ReportsPage = () => {
    const [reportUrl, setReportUrl] = useState('');
    const [companyData] = useState(() => {
        try {
            return JSON.parse(localStorage.getItem("companyData")) || {};
        } catch (error) {
            console.error("Error parsing companyData from local storage:", error);
            return {};
        }
    });

    useEffect(() => {
        const fetchReportUrl = async () => {
            try {
                const company_ID = companyData?.id;
                const response = await fetch(`${config.API_URL}/getReportURL.php`, {method: 'POST',headers: {  'Content-Type': 'application/json',},body: JSON.stringify({ company_ID }),});
                const data = await response.json();
                if (data.report_url) {
                    setReportUrl(data.report_url);
                } else {
                    console.error('Invalid response:', data);
                }
            } catch (error) {
                console.error('Error fetching report URL:', error);
            }
        };

        fetchReportUrl();
    }, []);

    return (
        <div className="reports-container">
            <div className="main-content">
                {reportUrl ? (
                    <iframe
                        src={reportUrl}
                        title="Report"
                        width="100%"
                        height="1000px"
                        style={{ border: 'none' }}
                    ></iframe>
                ) : (
                    <p>Loading report...</p>
                )}
            </div>
        </div>
    );
};

export default ReportsPage;
