import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../MSGraphAuthConfig";
import { uploadFileToSharePoint } from "../MSGraph"; // Import App token method
import config from './config';

const UploadFile = ({ companyId, siteId, onUploadSuccess }) => {
    const { instance, accounts } = useMsal();
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setUploadStatus("Please select a file first.");
            return;
        }

        let accessToken = null;

        try {
            if (accounts.length > 0) {
                // User is logged in, acquire token silently
                const response = await instance.acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                });
                accessToken = response.accessToken;
            } else {
                console.warn("No logged-in user detected. Using App Credentials Flow.");
                // Use Client Credentials Flow (App-only authentication)
                const response = await fetch(`${config.API_URL}/getAzureToken.php`);
                const data = await response.json();
                accessToken = data.access_token

            }

            if (!accessToken) {
                setUploadStatus("Authentication failed.");
                return;
            }

            const uploadResponse = await uploadFileToSharePoint(accessToken, siteId, companyId, selectedFile);
            if (uploadResponse) {
                setUploadStatus(`File uploaded successfully: ${uploadResponse.webUrl}`);
                onUploadSuccess(selectedFile.name);
            } else {
                setUploadStatus("Upload failed.");
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setUploadStatus("Error uploading file.");
        }
    };

    return (
        <div>
            <h3>Upload CSV & Update Power BI</h3>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload & Refresh Power BI</button>
            <p>{uploadStatus}</p>
        </div>
    );
};

export default UploadFile;
